/* =========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import Vue from 'vue';
import App from './App.vue';
// Vue Router
import router from './router';
// Vuex Store
import store from './store/store';
// Translation plugin
import i18n from './i18n';
import { tr } from './i18n';

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);

// Bootstrap UI Components for Vue
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

// -- element-ui: UI Components -- //
import {
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Row,
  Col,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Drawer,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Option,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Switch,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  TimePicker,
  Notification
} from 'element-ui';
import czLocale from 'element-ui/lib/locale/lang/cs-CZ';
import enLocale from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

let lang = czLocale;
if (i18n.locale === 'en') {
  lang = enLocale;
}
locale.use(lang);

Vue.use(Button);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Row);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Option);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(TimePicker);
Vue.prototype.$notify = Notification;
Vue.prototype.$ELEMENT = { zIndex: 100002, locale, bgColor: '#F00' };

// -- element-ui: UI Components -- //

// Vuejs - Vue wrapper for hammerjs // For mobile dev
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// V-tooltip: tooltip
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

// Theme Configurations
import '../themeConfig.js';
// Globally Registered Components
import './globalComponents.js';

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
// Tailwind
import '@/assets/css/tailwind.css';
// Styles: SCSS
import './assets/scss/main.scss';
// Feather font icon
require('./assets/css/iconfont.css');
// Custom Vue plugins
import Axios from '@/axios/Axios.js';
import ApiRepository from '@/axios/ApiRepository.js';
import { getRoute } from '@/Routering.js';
import fnc from '@/inc/funkce';
import auth from '@/inc/auth';
// socket server init
import io from 'socket.io-client';
const socket = io(process.env.VUE_APP_API_CHAT);

const plugin = {
  install() {
    Vue.$api = new ApiRepository(Axios);
    Vue.prototype.$api = new ApiRepository(Axios);
    Vue.$i18n = i18n;
    Vue.$tr = tr;
    Vue.prototype.$tr = tr;
    Vue.$auth = auth;
    Vue.prototype.$auth = auth;
    Vue.$fnc = fnc;
    Vue.prototype.$fnc = fnc;
    Vue.$getRoute = getRoute;
    Vue.prototype.$getRoute = getRoute;
    Vue.$router = router;
    Vue.$store = store;
    Vue.prototype.$socket = socket;
  }
};

Vue.use(plugin);

/* // Internationalization plugin
import VueI18n from 'vue-i18n';
import messages from './assets/lib/dict';

Vue.use(VueI18n);
// import utils from "./inc/utils"
// export default utils
const i18n = new VueI18n({
  locale: 'cs', // set locale
  messages
}); */

// axios
// import axios from "./axios.js"
// Vue.prototype.$http = axios

// Event bus
// Pro globalni emit mezi vsemi komponenty
// export const serverBus = new Vue();

/*Vue.mixin({
    mounted() {
    //console.log("load axios works")
    
    return
  }
})*/

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
