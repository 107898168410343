import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// rozsirena funkce t pro preklady
// predevsim pro parametrizovane preklady
//  key = klic prekladu (string, pr. 'nazev')
//  interpolations = pro nahrazeni parametru (%s, %i) hodnotou (object, pr. {'%s': ['Nazev1', 'Nazev2',..]}
export function tr(key, interpolations = undefined) {
  // pokud neni predan klic, netreba pokracovat
  if (!key) return '[error: key not defined]';

  const keyT = '__' + key + '__';

  try {
    let tr = Vue.$i18n.t(keyT);

    if (
      tr &&
      interpolations &&
      typeof interpolations === 'object' &&
      Object.keys(interpolations).length > 0
    ) {
      let curSplitTr = tr;
      // key = %s, %i,..
      Object.keys(interpolations).forEach((keyT, index) => {
        // val = text pro nahrazeni klice
        if (
          interpolations[keyT] &&
          interpolations[keyT] instanceof Array &&
          interpolations[keyT].length > 0
        ) {
          interpolations[keyT].forEach((val, i) => {
            curSplitTr = curSplitTr.replace(keyT, val);
          });
        }
      });

      tr = curSplitTr;
    }

    if (tr) {
      return tr;
    } else {
      return keyT;
    }
  } catch (e) {
    return keyT;
  }
}
/* export function tr(key, interpolations = []) {
  if (!key) return;

  try {
    let tr = Vue.$i18n.t('__' + key + '__');

    if (tr && interpolations.length > 0) {
      interpolations.forEach((value, index) => {
        tr = tr.replace('{' + index + '}', value);
      });
    }

    return tr;
  } catch (e) {
    return '';
  }
} */

export default new VueI18n({
  locale: localStorage['app_language'] || 'cs', // process.env.VUE_APP_I18N_LOCALE || 'cz',
  fallbackLocale: localStorage['app_language'], // process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'cz',
  messages: {}
});
