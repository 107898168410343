// import Vue from "vue"
import { tr } from './i18n';

/* route = path + section */
const defaultPaths = () => {
  return {
    main_page: {
      name: 'Úvod',
      path: '/',
      translation_key: 'uvod',
      url: ''
    },
    login: {
      name: 'Login',
      path: '/pages/login/',
      translation_key: 'prihlaseni',
      alt_name: 'Přihlášení'
    },
    chat: { name: 'Chat', path: '/chat', url: '/chat' },

    /* Uzivatel */
    user_tasks: {
      name: 'Uživatel - Úkoly',
      path: '/user/tasks/',
      translation_key: 'uzivatel',
      url: '/user/tasks/',
      alt_name: 'Uživatel'
    },
    user_usersheet: {
      name: 'Uživatel - Výkazy',
      path: '/user/usersheet/',
      translation_key: 'uzivatel_vykazy'
    },
    user_holiday: {
      name: 'Uživatel - Dovolená a práce na dálku',
      path: '/user/holiday/',
      translation_key: 'uzivatel_dovolena_a_prace_na_dalku'
    },
    user_supplysheet: {
      name: 'Náklady uživatele',
      path: '/user/supplysheet/',
      translation_key: 'uzivatel_naklady'
    },

    /* Dovolene - prehled */
    holidays_overview: {
      name: 'Dovolené - přehled',
      path: '/holidays-overview/',
      translation_key: 'dovolene_prehled'
    },

    /* Zakázky */
    orders: {
      name: 'Zakázky',
      path: '/orders/',
      translation_key: 'zakazky',
      url: '/orders/'
    },

    /* Kalkulace */
    pricelists: {
      name: 'Ceník',
      path: '/pricelists/',
      translation_key: 'cenik',
      url: '/pricelists/',
      alt_name: 'Ceník'
    },
    estimates: {
      name: 'Kalkulace',
      path: '/estimates/',
      translation_key: 'kalkulace',
      url: '/estimates/',
      alt_name: 'Kalkulace'
    },
    estimates_templates: {
      name: 'Šablony',
      path: '/estimates/templates/',
      translation_key: 'sablony',
      url: '/estimates/templates/',
      alt_name: 'Šablony'
    },

    /* Workflow */
    workflow: {
      name: 'Workflow',
      path: '/workflow/',
      translation_key: 'workflow',
      url: '/workflow/'
    },

    /* Vykazy */
    usersheet: {
      name: 'Výkazy',
      path: '/usersheet/',
      translation_key: 'vykazy'
    },

    /* Adresar */
    firms: {
      name: 'Adresář',
      path: '/firms/',
      translation_key: 'adresar',
      url: '/firms/'
    },

    /* Administrace */
    users: {
      name: 'Uživatelé',
      path: '/admin/users/',
      translation_key: 'uzivatele',
      url: '/admin/users/'
    },
    user_year_settings: {
      name: 'Čerpání dovolené',
      path: '/admin/user-year-settings/',
      translation_key: 'cerpani_dovolene',
      url: '/admin/user-year-settings/'
    },
    rangetypes: {
      name: 'Rangetypes',
      path: '/admin/rangetypes/',
      translation_key: 'rangetypes',
      url: '/admin/rangetypes/'
    },
    annualprices: {
      name: 'Roční plánované ceny',
      path: '/admin/annualprices/',
      translation_key: 'rocni_planovane_ceny',
      url: '/admin/annualprices/'
    },
    supplies: {
      name: 'Náklady',
      path: '/admin/supplies/',
      translation_key: 'naklady',
      url: '/admin/supplies/'
    },
    useroverheads: {
      name: 'Overheads',
      path: '/admin/useroverheads/',
      translation_key: 'overheads',
      url: '/admin/useroverheads/'
    },
    indexes: {
      name: 'Indexy',
      path: '/admin/indexes/',
      translation_key: 'indexy',
      url: '/admin/indexes/'
    },
    workgroups: {
      name: 'Oddělení',
      path: '/admin/workgroups/',
      translation_key: 'oddeleni',
      url: '/admin/workgroups/'
    },
    jobtypes: {
      name: 'Typy úkolů',
      path: '/admin/jobtypes/',
      translation_key: 'typy_ukolu',
      url: '/admin/jobtypes/'
    },

    /* Notifikace */
    notifications: {
      name: 'Notifikace',
      path: '/notifications/',
      translation_key: 'notifikace',
      url: '/notifications/'
    }
  };
};

const paths = defaultPaths();

const sections = {
  list: { name: '', path: 'list' }, // - seznam
  new: { name: ' - nový', path: 'new' },
  detail: { name: ' - detail', path: 'detail&item=:id' }
};

export const getRoute = (path_key, section_key = false) => {
  let sectionName = '';
  let sectionPath = '';

  if (section_key !== false) {
    sectionName = sections[section_key].name;
    sectionPath = sections[section_key].path;
  }

  if (!Object.keys(paths).includes(path_key)) {
    // Vue.$fnc.notificationAlert('Daná route není definovaná!', 'warning');
    return paths.default;
  }

  return {
    // name: (tr(paths[path_key].translation_key)) + sectionName,
    name: paths[path_key].name + sectionName,
    path: paths[path_key].path + sectionPath,
    url:
      (paths[path_key].url ? paths[path_key].url : paths[path_key].path) +
      sectionPath,
    // url: paths[path_key].path + sectionPath,
    path_key: path_key,
    translation_key: paths[path_key].translation_key,
    alt_name: paths[path_key].alt_name
  };
};

/* set last visited subsection path in each paths */
export const setLastRoute = (path_key, path) => {
  if (!Object.keys(paths).includes(path_key)) {
    // Vue.$fnc.notificationAlert('Daná route není definovaná! Nelze nastavit poslední navštívenou podsekci sekce!', 'warning');
    return false;
  }

  paths[path_key].url = path;
};
