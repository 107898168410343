/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import Vue from 'vue';

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },
  /* updateFooter({ commit }, val) {
      commit('UPDATE_FOOTER_TYPE', val)
    }, */
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  'request-verify': async ({ commit, dispatch }) => {
    const data = await Vue.$api.index('login', 'request-verify');

    let user = data.data['account_detail'];
    if (user && user.id > 0) {
      commit('UPDATE_USER_INFO', user);

      commit('set', ['masterdata', 'languages', data.data['languages']], {
        root: true
      });

      // i18n
      await dispatch(
        'setAppTranslations',
        data.settings['default_app_language']
      );
      // notifikace
      await dispatch('app-notifications');

      return true;
    } else {
      Vue.$auth.deleteTokenAndReloadPage();
    }

    return false;
  },

  // app notifications
  'app-notifications': async ({ commit, dispatch }) => {
    const data = await Vue.$api.get('login', 'app-notifications');

    commit('set', ['masterdata', 'notifications', data['notifications']], {
      root: true
    });
    dispatch('setNotificationsChangeDetected');

    return data;
  },

  // request prekladu
  translations: async ({ commit }) => {
    const data = await Vue.$api.get(
      'public',
      'translations' /* {
      locale: Vue.$i18n.locale
    } */
    );

    if (data && data['translations']) {
      localStorage.setItem(
        'translations',
        JSON.stringify(data['translations'])
      );

      commit('SET_APP_TRANSLATIONS', data['translations'], {
        root: true
      });
    }
  },

  setAppTranslations: async ({ dispatch }, default_language) => {
    if (
      (!localStorage['app_language'] || !localStorage['translations']) &&
      default_language
    ) {
      await dispatch('setAppLanguage', default_language);
    }

    await dispatch('translations');
    //commit('SET_APP_TRANSLATIONS');
  },

  setAppLanguage: async (context, lang) => {
    if (lang) {
      Vue.$i18n.locale = lang.toLowerCase();
    }
    localStorage.setItem('app_language', Vue.$i18n.locale);

    return true;
  },

  'app-info': async ({ dispatch }) => {
    const data = await Vue.$api.index('public', 'app-info');

    await dispatch('setAppTranslations', data.settings['default_app_language']);
  },

  'default-page': async () => {
    await Vue.$api.index('default', '');
  },

  // Update user status db
  'userstatus-update': async ({ commit, rootGetters }, payload) => {
    const data = await Vue.$api.index('chat', 'userstatus-update', payload);

    if (Vue.$auth.searchInStatusList(data.status_list, 'ok')) {
      commit('UPDATE_USER_INFO', {
        status: payload.status_name,
        last_online: rootGetters['DEFAULT_DATETIME']
      });

      return true;
    }

    return false;
  },

  // update last online
  'update-last-online': async (context, payload) => {
    await Vue.$api.index('login', 'update-last-online', payload);
  },

  // mark messages delivered
  'update-markseen': async (context, payload) => {
    const data = await Vue.$api.index('chat', 'update-markseen', payload);

    if (Vue.$auth.searchInStatusList(data.status_list, 'ok')) {
      return true;
    }

    return false;
  },

  updateLoggedUserInfo: async ({ commit }, payload) => {
    commit('UPDATE_USER_INFO', payload);
  },

  updateUnseenMessages: async ({ commit }, payload) => {
    commit('UPDATE_UNSEEN_MESSAGES', payload);
  }

  /* removeUserData: async ({ commit, dispatch }, payload) => {
      commit('REMOVE_USER_DATA')
    }, */
  /* updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    }, */
};

export default actions;
