import { extend } from 'vee-validate';
import {
  required,
  email,
  numeric,
  double,
  min_value
} from 'vee-validate/dist/rules';
import { tr } from '../../i18n';

/* extend('number', value => {
    return value >= 0;
}) */

extend('numeric', {
  ...numeric,
  message: tr('toto_pole_musi_obsahovat_pouze_cisla')
});

extend('email', {
  ...email,
  message: tr('neplatny_format_emailu')
});

extend('required', {
  ...required,
  message: tr('toto_pole_je_nutne_vyplnit')
});

extend('double', {
  ...double,
  message: tr('toto_pole_musi_obsahovat_pouze_cisla')
});

extend('min_value', {
  ...min_value,
  message: tr('toto_pole_je_nutne_vyplnit')
});
