/*=========================================================================================
  File Name: masterdata.js
  Description: Global data storage
==========================================================================================*/
import Vue from 'vue';
import { tr } from '../../i18n';

const moduleName = 'masterdata';

const state = {
  annualprices: [],
  bookmarkIcons: {
    1: 'info',
    2: 's-order',
    3: 'suitcase',
    4: 'set-up'
  },
  currencies: [],
  estimates: [],
  estimateTemplates: [],
  firms: [],
  firmCodes: [],
  indexes: [],
  indexesGroups: [],
  jobtypes: [],
  languages: [],
  notificationsChangeDetected: '',
  notifications: [],
  orders: [],
  ordersTiming: [],
  ordersTypes: [],
  ordersStatuses: [],
  pricelists: [],
  printdata: [],
  rangetypes: [],
  responsibles: [],
  supplies: [],
  users: [],
  usersGrafik: [],
  usersArtwork: [],
  usersAccount: [],
  units: [],
  vats: [],
  workflowPriorities: [
    /* { value: 2, label: 'Normální' },
    { value: 3, label: 'Vysoká' },
    { value: 1, label: 'Nízká' },
    { value: 0, label: 'Pozastaveno' } */
  ],
  workflowStatuses: [
    /* { id: 1, name: 'Aktivní' },
    { id: 2, name: 'Pozastaveno' },
    { id: 3, name: 'Ke kontrole' },
    { id: 4, name: 'Schváleno' },
    { id: 5, name: 'Právě probíhá' } */
  ],
  workflowControlTypes: [],
  workgroups: [],

  removedNotification: null
};

const getters = {
  HOLIDAY_LABELS_DESCRIPTION: (state) => {
    return [
      {
        text: tr('dovolena') + ' (' + tr('schvalena') + ')',
        color: 'primary'
      },
      {
        text: tr('kratkodobe_uvolneni') + ' (' + tr('schvalena') + ')',
        color: 'info'
      },
      {
        text: tr('prace_z_domova') + ' (' + tr('schvalena') + ')',
        color: 'warning'
      },
      {
        text: tr('ceka_na_schvaleni'),
        color: 'danger'
      },
      {
        text: tr('neschvaleno'),
        color: 'dark'
      },
      {
        text: tr('bez_oznaceni'),
        color: 'lightpurple'
      }
    ];
  },

  HOLIDAY_LABELS: (state) => {
    return [
      {
        id: 1,
        text: tr('dovolena'),
        color: 'primary',
        value: 'holiday'
      },
      {
        id: 3,
        text: tr('kratkodobe_uvolneni'),
        color: 'info',
        value: 'release'
      },
      {
        id: 0,
        text: tr('prace_z_domova'),
        color: 'warning',
        value: 'homeoffice'
      },
      {
        id: 2,
        text: tr('bez_oznaceni'),
        color: 'lightpurple',
        value: 'none'
      }
    ];
  },
  GET_HOLIDAY_LABELS: (state, getters) => (val) => {
    const holidayLabel = Vue.$fnc.arrayFind(
      getters['HOLIDAY_LABELS'],
      'value',
      val
    );
    if (holidayLabel) return holidayLabel;
    else return {};
  },

  USERS: (state) => state.users,

  USERS_ACTIVE: (state) => {
    return Vue.$fnc
      .arrayFilter(state.users, 'active', 'Y')
      .sort(Vue.$fnc.dynamicSort('surname'));
  },

  USERS_SELECT: (state) => {
    let temp = [];
    state.users.forEach((user) => {
      if (user.active === 'Y') {
        temp.push({
          ...user,
          label: `${user.name} ${user.surname}`,
          value: user.id
        });
      }
    });

    return temp;
  },

  USERS_GRAFIK_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.usersGrafik, 'id', 'fullname'),

  USERS_ARTWORK_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.usersArtwork, 'id', 'fullname'),

  USERS_ACCOUNT_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.usersAccount, 'id', 'fullname'),

  WORKGROUPS: (state) => state.workgroups,

  WORKGROUPS_SELECT: (state) => Vue.$fnc.createSelectOptions(state.workgroups),

  RANGETYPES: (state) => state.rangetypes,

  RANGETYPES_SELECT: (state) => {
    let temp = [];
    state.rangetypes.forEach((rangetype) => {
      temp.push({
        label: rangetype.name,
        value: rangetype.id,
        annualfixedprice: rangetype.annualfixedprice_id
      });
    });

    return temp;
  },

  RESPONSIBLES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.responsibles, 'value', 'name'),

  SUPPLIES: (state) => state.supplies,

  SUPPLIES_SELECT: (state) => Vue.$fnc.createSelectOptions(state.supplies),

  ANNUALPRICES: (state) => state.annualprices,

  ANNUALPRICES_SELECT: (state) => {
    let temp = [];
    state.annualprices.forEach((annualprice) => {
      temp.push({
        label: `${Vue.$fnc.numberFormatter(annualprice.price)} ${
          annualprice.currency_code
        } - ${tr('pro_rok')} ${annualprice.period_for} ${
          annualprice.name ? '(' + annualprice.name + ')' : ''
        }`,
        value: annualprice.id
      });
    });

    return temp;
  },

  CURRENCIES: (state) => {
    return state.currencies;
  },

  CURRENCIES_SELECT: (state) => {
    let temp = [];
    state.currencies.forEach((currency) => {
      // currency.id je z sys_currencies2 ID !!
      temp.push({
        ...currency,
        label:
          currency.id > 1
            ? currency.code +
              ' - ' +
              Vue.$fnc.dateFormat(new Date(currency.adddate), 'cs') +
              ' - ' +
              tr('kurz') +
              ': ' +
              Vue.$fnc.numberFormatter(currency.amount)
            : currency.code,
        value: currency.id
      });
    });

    return temp;
  },

  JOBTYPES: (state) => state.jobtypes,

  JOBTYPES_SELECT: (state) => {
    return Vue.$fnc.createJobtypesSelect(state.jobtypes);

    /* let jobtypesSelect = [];

    if (Vue.$fnc.isArray(state.jobtypes)) {
      state.jobtypes.forEach((item) => {
        if (
          !Vue.$fnc.arrayFind(
            jobtypesSelect,
            'value',
            item.workgroups_id + item.workgroups_name
          )
        ) {
          jobtypesSelect.push({
            value: item.workgroups_id + item.workgroups_name,
            label: item.workgroups_name,
            disabled: true,
            _classes: 'underline'
          });
        }

        jobtypesSelect.push({
          ...item,
          value: parseInt(item.id),
          label: String(item.name + ' ( ' + item.workgroups_name + ' )'),
          _classes: 'ml-2'
        });
      });
    }

    return jobtypesSelect; */
    // Vue.$fnc.createSelectOptions(state.jobtypes)
  },

  INDEXES: (state) => state.indexes,
  INDEXES_BRAND: (state) => {
    return Vue.$fnc.createSelectOptions(
      Vue.$fnc.arrayFilter(state.indexes, 'type_name', 'brand')
    );
  },
  INDEXES_STATUS: (state) => {
    return Vue.$fnc.createSelectOptions(
      Vue.$fnc.arrayFilter(state.indexes, 'type_name', 'status'),
      'posindex'
    );
  },
  INDEXES_ONLINE_STATUS: (state) =>
    Vue.$fnc.arrayFilter(state.indexes, 'type_name', 'online_status'),
  INDEXES_CORRECTION: (state) =>
    Vue.$fnc.arrayFilter(state.indexes, 'type_name', 'correction'),
  INDEXES_BOOKMARK: (state) =>
    Vue.$fnc.arrayFilter(state.indexes, 'type_name', 'bookmark'),

  INDEXES_GROUPS: (state) => state.indexesGroups,

  INDEXES_TYPENAME_SELECT: (state) => {
    let temp = [];
    state.indexes.forEach((index) => {
      if (!Vue.$fnc.arrayFind(temp, 'value', index.type_name)) {
        temp.push({
          label: index.type_name,
          value: index.type_name
        });
      }
    });

    return temp;
  },

  FIRMS: (state) => state.firms,

  FIRMS_SELECT: (state) => {
    let temp = [];
    state.firms.forEach((item) => {
      temp.push({
        ...item,
        value: item.id,
        label: (item.code ? item.code + ' - ' : '') + item.name
      });
    });

    return temp;
    // Vue.$fnc.createSelectOptions(state.firms)
  },

  FIRM_CODES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.firmCodes, 'code', 'code'),

  PRICELISTS: (state) => state.pricelists,

  PRICELISTS_SELECT: (state, getters, rootState, rootGetters) => {
    let temp = [];
    state.pricelists.forEach((item) => {
      temp.push({
        ...item,
        value: item.id,
        label:
          item.name +
          (!rootGetters['IS_DEFAULT_DATE'](item.date_doc)
            ? ' - ' + Vue.$fnc.dateToCz(item.date_doc)
            : '')
      });
    });

    return temp;
  },

  PRINTDATA_SELECT: (state) => Vue.$fnc.createSelectOptions(state.printdata),

  ESTIMATES: (state) => state.estimates,

  ESTIMATES_SELECT: (state) => Vue.$fnc.createSelectOptions(state.estimates),

  ESTIMATE_TEMPLATES: (state) => state.estimateTemplates,

  ORDERS: (state) => state.orders,

  ORDERS_SELECT: (state) => {
    let temp = [];
    state.orders.forEach((item) => {
      temp.push({
        ...item,
        value: item.id,
        label: item.code + ' - ' + item.name
      });
    });

    return temp;
    // Vue.$fnc.createSelectOptions(state.orders, 'id', 'code')
  },

  ORDERS_TIMING_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.ordersTiming),

  ORDERS_TYPES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.ordersTypes),

  ORDERS_STATUSES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.ordersStatuses),

  LANGUAGES: (state) => state.languages,

  SYSTEM_LANGUAGES: (state) => {
    return state.languages;
  },

  LANGUAGES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.languages, 'id', 'shortname'),

  VATS: (state) => state.vats,

  VATS_SELECT: (state) => Vue.$fnc.createSelectOptions(state.vats),

  UNITS: (state) => state.units,

  UNITS_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.units, 'id', 'shortname'),

  NOTIFICATIONS_CHANGE_DETECTED: (state) => state.notificationsChangeDetected,

  NOTIFICATIONS: (state) => state.notifications,

  NOTIFICATION: (state) => (path_key, id) => {
    return state.notifications.find(
      (notifi) => notifi.path_key == path_key && notifi.id == id
    );
  },

  TIMEPRESUMPTION_TYPES: (state) => {
    return [
      { value: 'hod', label: tr('hod') },
      { value: 'den', label: tr('den') }
    ];
  },

  WORKFLOW_PRIORITIES: (state) => state.workflowPriorities,

  WORKFLOW_PRIORITIES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.workflowPriorities),

  GET_WORKFLOW_PRIORITY: (state) => (id) => {
    const temp = Vue.$fnc.arrayFind(state.workflowPriorities, 'id', id);
    if (temp) return temp;
    else return {};
  },

  WORKFLOW_CONTROL_TYPES: (state) => state.workflowControlTypes,

  GET_WORKFLOW_CONTROL_TYPE: (state) => (type) => {
    const temp = Vue.$fnc.arrayFind(state.workflowControlTypes, 'type', type);
    if (temp) return temp;
    else return { name_human: 'accountem' }; // defaultne kontroluje account, pro info
  },

  WORKFLOW_STATUSES_SELECT: (state) =>
    Vue.$fnc.createSelectOptions(state.workflowStatuses),

  GET_WORKFLOW_STATUS: (state) => (id) => {
    const temp = Vue.$fnc.arrayFind(state.workflowStatuses, 'id', id);
    if (temp) return temp;
    else return {};
  },

  BOOKMARK_ICON: (state) => (bookmarkId) =>
    'el-icon-' + state.bookmarkIcons[bookmarkId]
};

const mutations = {
  NOTIFICATION_REMOVE(state, payload) {
    state.removedNotification = payload.path_key + payload.id;
    state.notifications = state.notifications.filter(
      (notifi) =>
        !(
          Vue.$fnc.compareValues(notifi.path_key, payload.path_key) &&
          Vue.$fnc.compareValues(notifi.id, payload.id)
        )
    );
    /* co to bylo.. state.notifications = state.notifications.filter(
      (notifi) =>
        Vue.$fnc.compareValues(notifi.path_key, payload.path_key) &&
        !Vue.$fnc.compareValues(notifi.id, payload.id)
    ); */
  },

  SET_NOTIFICATIONS_CHANGE_DETECTED(state) {
    state.notificationsChangeDetected = !state.notificationsChangeDetected;
  }
};

const actions = {
  'notifications-remove': async ({ commit }, payload) => {
    await commit('NOTIFICATION_REMOVE', payload);

    return true;
  },

  setNotificationsChangeDetected({ commit }) {
    commit('SET_NOTIFICATIONS_CHANGE_DETECTED');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
