import { getRoute } from "@/Routering.js";

export default {
  pages: {
    key: "title",
    data: [
      {title: getRoute('main_page').name, url: getRoute('main_page').url, icon: 'HomeIcon', is_bookmarked: false},
      {title: getRoute('chat').name, url: getRoute('chat').url, icon: 'FileIcon', is_bookmarked: false},

      {title: getRoute('user_tasks').alt_name, url: getRoute('user_tasks').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('holidays_overview').name, url: getRoute('holidays_overview').url, icon: 'FileIcon', is_bookmarked: false},

      {title: getRoute('orders').name, url: getRoute('orders').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('pricelists').alt_name, url: getRoute('pricelists').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('estimates').alt_name, url: getRoute('estimates').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('estimates_templates').alt_name, url: getRoute('estimates_templates').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('workflow').name, url: getRoute('workflow').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('usersheet').name, url: getRoute('usersheet').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('firms').name, url: getRoute('firms').url, icon: 'FileIcon', is_bookmarked: false},

      {title: getRoute('users').name, url: getRoute('users').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('user_year_settings').name, url: getRoute('user_year_settings').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('rangetypes').name, url: getRoute('rangetypes').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('annualprices').name, url: getRoute('annualprices').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('supplies').name, url: getRoute('supplies').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('useroverheads').name, url: getRoute('useroverheads').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('indexes').name, url: getRoute('indexes').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('workgroups').name, url: getRoute('workgroups').url, icon: 'FileIcon', is_bookmarked: false},
      {title: getRoute('jobtypes').name, url: getRoute('jobtypes').url, icon: 'FileIcon', is_bookmarked: false},

      {title: getRoute('notifications').name, url: getRoute('notifications').url, icon: 'FileIcon', is_bookmarked: false},
    ]
  }
}
