var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-table"},[_c('base-card',{class:{ 'mb-2': _vm.paddingBottom },attrs:{"add-classes-header":{ 'has-sticky-header': _vm.hasStickyHeader },"add-styles-header":_vm.hasStickyHeader ? 'top: ' + _vm.stickyHeaderPos + 'px;' : '',"header":_vm.showHeader,"card":_vm.card}},[_c('template',{slot:"header"},[(_vm.showHeader || (_vm.filterable && _vm.canUseQueryFilter))?_c('div',{staticClass:"mx-1 inline-flex w-full align-items-center"},[_c('div',{staticClass:"text-lg inline-flex align-items-center"},[_vm._t("header")],2),_c('vs-spacer'),_vm._t("header-items"),(_vm.filterable && _vm.filterKeys.length > 0 && _vm.canUseQueryFilter)?_c('el-input',{staticClass:"lg:ml-2",staticStyle:{"width":"200px"},attrs:{"size":"small","placeholder":_vm.$tr('vyhledavani')},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e()],2):_vm._e()]),(_vm.$scopedSlots['over-table'])?_c('div',{staticClass:"mb-2"},[_vm._t("over-table")],2):_vm._e(),_c('vs-table',{key:_vm.tableId,staticClass:"bg-white border-radius-none h-full table-hover",class:[_vm.tableClasses, { 'table-body-inherit': _vm.tableBodyInherit }],attrs:{"id":_vm.tableId,"data":_vm.tableItemsWithPagination,"no-data-text":null,"pagination":false,"max-items":_vm.itemsMax,"hover-flat":""},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',{ref:"base-table-body",attrs:{"id":_vm.draggableGroupId}},[_vm._l((data),function(item,itemIndex){return [_vm._t("vs-tr",function(){return [(_vm.$fnc.isObject(_vm.getItem(item)))?_c('tr',{key:_vm.draggableItems && _vm.getItem(item).id > 0
                    ? _vm.getItem(item).id
                    : _vm.getItem(item).id > 0
                    ? itemIndex + '-' + _vm.getItem(item).id
                    : itemIndex,staticClass:"tr-values vs-table--tr tr-table-state-null hoverFlat",class:[
                  {
                    'cursor-pointer': _vm.clickable && _vm.clickableCursorPointer,
                    'bg-light': itemIndex % 2 === 0
                  },
                  _vm.getItem(item)._classes
                ],on:{"click":function($event){$event.stopPropagation();return _vm.handleRowClick(item)},"dblclick":function($event){return _vm.$emit('row-dblclick', item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.stopPropagation();return _vm.$emit('row-esc')}}},[(_vm.draggableItems)?_c('td',{staticClass:"vs-table--td"},[_c('feather-icon',{staticClass:"d-flex my-auto w-5 h-5 cursor-pointer drag-handler",attrs:{"icon":"MenuIcon"}})],1):_vm._e(),_vm._l((_vm.tableFields),function(field){return [_vm._t('td-' + field.key,function(){return [_c('vs-td',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getItem(item)[field.key_tooltip] ?? ''),expression:"getItem(item)[field.key_tooltip] ?? ''"}],key:field.key + '-' + _vm.getItem(item).id,class:[field._classes, field._td_classes],style:(field._styles + ';' + field._td_styles)},[_vm._t(field.key,function(){return [(field.formatter === 'currency')?[_vm._v(" "+_vm._s(_vm.$fnc.currencyFormatter(_vm.getItem(item)[field.key]))+" ")]:(field.formatter === 'currency_with_code')?[_vm._v(" "+_vm._s(_vm.$fnc.currencyFormatter( _vm.getItem(item)[field.key], undefined, { currency_code: item.currency_code } ))+" ")]:(field.formatter === 'number')?[_vm._v(" "+_vm._s(_vm.$fnc.numberFormatter(_vm.getItem(item)[field.key]))+" ")]:(field.formatter === 'trim_number')?[_vm._v(" "+_vm._s(_vm.$fnc.trimNumber(_vm.getItem(item)[field.key]))+" ")]:(field.formatter === 'sec_to_time')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            _vm.$fnc.secToTime(_vm.getItem(item)[field.key], true)
                          ),expression:"\n                            $fnc.secToTime(getItem(item)[field.key], true)\n                          "}]},[_vm._v(" "+_vm._s(_vm.$fnc.secToTime(_vm.getItem(item)[field.key]))+" ")]):(
                            field.formatter === 'sec_to_time_round_minutes'
                          )?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            _vm.$fnc.secToTime(_vm.getItem(item)[field.key], true)
                          ),expression:"\n                            $fnc.secToTime(getItem(item)[field.key], true)\n                          "}]},[_vm._v(" "+_vm._s(_vm.$fnc.secToTime( _vm.getItem(item)[field.key], false, true ))+" ")]):(field.formatter === 'date')?[(!_vm.IS_DEFAULT_DATE(_vm.getItem(item)[field.key]))?[_vm._v(" "+_vm._s(_vm.$fnc.dateToCz(_vm.getItem(item)[field.key]))+" ")]:_vm._e()]:[_vm._v(_vm._s(_vm.getItem(item)[field.key]))]]},{"item":item,"index":itemIndex})],2)]},{"item":item,"index":itemIndex})]})],2):_vm._e()]},{"item":item,"index":itemIndex}),(_vm.showUnderItem)?_c('tr',{key:'under-item-' + itemIndex + '-' + _vm.getItem(item).id,staticClass:"under-item",class:{
                'tr-values vs-table--tr tr-table-state-null hoverFlat':
                  _vm.underItemFlat
              }},[_vm._t("tr-under-item",null,{"item":item,"index":itemIndex})],2):_vm._e()]})],2),(_vm.$slots['tbody-tr-bottom'])?_c('tbody',[_c('vs-tr',{key:'tbody-tr-bottom-' + _vm.tableId,staticClass:"cursor-auto font-weight-bold no-wrap",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.draggableItems)?_c('td',{staticClass:"vs-table--td"}):_vm._e(),_vm._t("tbody-tr-bottom")],2)],1):_vm._e(),(_vm.addNewItem)?_c('tbody',{staticClass:"table-footer"},[_c('vs-tr',[_vm._l((_vm.newFields),function(field,i){return _c('vs-td',{key:i,class:[field._classes, field._td_classes],style:(field._styles + ';' + field._td_styles)},[_vm._t('new-' + field.key,function(){return [(field.component === 'input')?[_c('base-input',{attrs:{"value":_vm.newItem[field.key],"name":'new-' + field.key,"type":field.type,"disabled":field.disabled,"maxlength":field.maxlength,"only-numbers":field.onlyNumbers,"step":field.step,"min":field.min,"add-classes":"w-full","size":"small"},on:{"update:value":function($event){return _vm.$set(_vm.newItem, field.key, $event)},"enter":function($event){return _vm.$emit('save-new-item')},"esc":function($event){return _vm.$emit('edit-esc')}}})]:(field.component === 'select')?[_c('div',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();}}},[_c('el-select',{staticClass:"w-full",attrs:{"placeholder":"","no-data-text":" ","clearable":"","filterable":"","default-first-option":"","size":"mini"},model:{value:(_vm.newItem[field.key]),callback:function ($$v) {_vm.$set(_vm.newItem, field.key, $$v)},expression:"newItem[field.key]"}},_vm._l(([
                          { label: _vm.$tr('vyberte'), value: 0 },
                          ...field.componentData
                        ]),function(opt,index){return _c('el-option',{key:index + '' + opt.value,attrs:{"label":String(opt.label),"value":_vm.$fnc.isNumber(opt.value)
                            ? Number(opt.value)
                            : opt.value}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(opt.label))])])}),1)],1)]:(field.component === 'checkbox')?[_c('el-checkbox',{staticClass:"my-auto",attrs:{"true-label":_vm.isChecked,"false-label":_vm.notChecked},model:{value:(_vm.newItem[field.key]),callback:function ($$v) {_vm.$set(_vm.newItem, field.key, $$v)},expression:"newItem[field.key]"}})]:_vm._e()]})],2)}),(!_vm.noSaveButton)?_c('vs-td',[_c('div',{staticClass:"button-group"},[_c('base-button',{staticClass:"text-white",attrs:{"size":"small","icon":"check"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();},"click":function($event){return _vm.$emit('save-new-item')}}}),(_vm.newItem && _vm.newItem.id)?_c('base-button',{attrs:{"size":"small","color":"danger","icon":"x"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();},"click":function($event){return _vm.$emit('edit-esc')}}}):_vm._e()],1)]):_vm._e()],2)],1):_vm._e()]}}],null,true)},[(!_vm.noTableHeader)?_c('template',{slot:"thead"},[(_vm.draggableItems)?_c('th'):_vm._e(),_vm._l((_vm.tableFields),function(itemLabel,i){return [_vm._t('th-' + itemLabel.key,function(){return [_c('th',{key:itemLabel.key + '-' + i,class:['pre-line', itemLabel._classes, itemLabel._th_classes],staticStyle:{"height":"30px"},style:(itemLabel._styles + ';' + itemLabel._th_styles),attrs:{"id":itemLabel.key + '-' + i}},[_c('div',{class:{
                  'd-flex align-items-center justify-content-between':
                    itemLabel.label_info
                }},[_vm._t('th-value-' + itemLabel.key,function(){return [_vm._v(" "+_vm._s(itemLabel.label)+" "),(itemLabel.label_info)?_c('feather-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(itemLabel.label_info),expression:"itemLabel.label_info"}],attrs:{"icon":"InfoIcon","svg-classes":"h-4 w-4 text-primary ml-2 mb-1"}}):_vm._e()]})],2)])]})]})],2):_vm._e()],2),(_vm.pagination)?_c('base-row',{staticClass:"justify-content-center mt-3"},[_c('base-col',{staticClass:"pr-1",attrs:{"sm":"auto"}},[_c('vs-pagination',{staticClass:"pagination-sm",attrs:{"total":_vm.totalPages},model:{value:(_vm.activePageCurrent),callback:function ($$v) {_vm.activePageCurrent=$$v},expression:"activePageCurrent"}})],1),_c('base-col',{staticClass:"pl-1",attrs:{"sm":"auto"}},[_c('base-select',{staticStyle:{"width":"80px"},attrs:{"options":_vm.$fnc.createSelectOptions(_vm.itemsPerPageSelect, 'value', 'value'),"value":_vm.itemsPerPageCurrent,"clearable":false,"no-default-value":"","size":"small"},on:{"update:value":function($event){_vm.itemsPerPageCurrent=$event}}})],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }