/*=========================================================================================
  File Name: workflow.js
  Description: actions requests to workflow.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'workflow';

const actions = {
  list: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'list', payload);

    commit('set', ['masterdata', 'users', data.data['users']], { root: true });
    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'workflowStatuses', data.data['workflow_statuses']],
      {
        root: true
      }
    );
    commit(
      'set',
      [
        'masterdata',
        'workflowControlTypes',
        data.data['workflow_control_types']
      ],
      {
        root: true
      }
    );

    return data;
  },

  'workflow-form': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'workflow-form', payload);

    commit('set', ['masterdata', 'orders', data.data['orders']], {
      root: true
    });
    commit('set', ['masterdata', 'usersAccount', data.data['users_account']], {
      root: true
    });
    commit('set', ['masterdata', 'usersGrafik', data.data['users_grafik']], {
      root: true
    });
    commit('set', ['masterdata', 'usersArtwork', data.data['users_artwork']], {
      root: true
    });
    commit('set', ['masterdata', 'jobtypes', data.data['jobtypes']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'workflowPriorities', data.data['workflow_priorities']],
      {
        root: true
      }
    );
    commit(
      'set',
      ['masterdata', 'workflowStatuses', data.data['workflow_statuses']],
      {
        root: true
      }
    );
    commit(
      'set',
      [
        'masterdata',
        'workflowControlTypes',
        data.data['workflow_control_types']
      ],
      {
        root: true
      }
    );

    return data.data;
  },

  'workflow-save': async (context, payload) => {
    const data = await Vue.$api.postFiles(
      moduleName,
      'workflow-save',
      payload.files,
      payload.data
    );

    return data;
  },

  'prefill-workflow': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'prefill-workflow', payload);

    return data;
  },

  'workflow-multiple-save': async (context, payload) => {
    const data = await Vue.$api.postFiles(
      moduleName,
      'workflow-multiple-save',
      payload.files,
      payload.data
    );

    return data;
  },

  'workflow-tpl-multiple-save': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'workflow-tpl-multiple-save',
      payload
    );

    return data.saved;
  },

  'workflow-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'workflow-remove', payload);

    return data.success;
  },

  'task-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'task-detail', payload);

    if (!Vue.$fnc.isObjectEmpty(data.data)) {
      commit(
        'set',
        ['masterdata', 'workflowPriorities', data.data['workflow_priorities']],
        {
          root: true
        }
      );
      commit(
        'set',
        ['masterdata', 'workflowStatuses', data.data['workflow_statuses']],
        {
          root: true
        }
      );
      commit(
        'set',
        [
          'masterdata',
          'workflowControlTypes',
          data.data['workflow_control_types']
        ],
        {
          root: true
        }
      );
    }

    return data.data;
  },

  'control-task': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'control-task', payload);

    return data;
  },

  'task-usersheet': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'task-usersheet', payload);

    return data.data;
  },

  'task-assign': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'task-assign', payload);

    return data.saved;
  },

  'task-unassign': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'task-unassign', payload);

    return data.saved;
  },

  'task-duplicate': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'task-duplicate', payload);

    return data.saved;
  },

  'task-priority-update': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'task-priority-update',
      payload
    );

    return data.saved;
  },

  'taskstatus-update': async (context, payload) => {
    const data = await Vue.$api.postFiles(
      moduleName,
      'taskstatus-update',
      payload.files,
      payload
    );

    return data;
  },

  'jobtypes-types': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'jobtypes-types', payload);

    return data;
  },

  'orderworkflow-list': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'orderworkflow-list', payload);

    return data;
  },

  'correction-number': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'correction-number', {
      order2_field_id: payload
    });

    return data;
  },

  'workflow-tpl-form': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'workflow-tpl-form', payload);

    commit('set', ['masterdata', 'usersGrafik', data.data['users_grafik']], {
      root: true
    });
    commit('set', ['masterdata', 'jobtypes', data.data['jobtypes']], {
      root: true
    });

    return data.data;
  },

  'workflow-tpl-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'workflow-tpl-save',
      payload
    );

    return data.saved;
  },

  'workflow-tpl-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'workflow-tpl-remove',
      payload
    );

    return data.success;
  },

  'task-from-template-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'task-from-template-save',
      payload
    );

    return data.saved;
  },

  'set-task-date-limit': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'set-task-date-limit',
      payload
    );

    return data.saved;
  },

  'rangeworkflowlink-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'rangeworkflowlink-remove',
      payload
    );

    return data.success;
  },

  'modal-return-task': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'modal-return-task', payload);

    commit(
      'set',
      ['masterdata', 'workflowControlTypes', data['workflow_control_types']],
      {
        root: true
      }
    );
    commit('set', ['masterdata', 'usersGrafik', data['users_grafik']], {
      root: true
    });
    commit('set', ['masterdata', 'usersArtwork', data['users_artwork']], {
      root: true
    });

    return data;
  },

  'get-task-files': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'get-task-files', payload);

    return data;
  },

  'get-user-tasks': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'get-user-tasks', payload);

    return data;
  },

  'get-rangeworkflowlink-files': async ({ commit }, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'get-rangeworkflowlink-files',
      payload
    );

    commit(
      'set',
      ['masterdata', 'workflowControlTypes', data['workflow_control_types']],
      {
        root: true
      }
    );

    return data;
  },

  'get-usersheet-files': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'get-usersheet-files', payload);

    return data;
  },

  'get-returned-task-files': async (context, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'get-returned-task-files',
      payload
    );

    return data;
  }
};

export default {
  namespaced: true,
  actions
};
