<!-- Reusable form component -->
<template>
  <validation-observer v-slot="{ handleSubmit }">
    <div :class="{ 'border p-5': !noBorder }" tabindex="0" class="bg-white">
      <form
        ref="base-form"
        action.prevent
        autocomplete="on"
        @submit.prevent="(e) => handleSubmit(() => saveData(e))"
      >
        <input v-if="item.id > 0" name="id" :value="item.id" type="hidden" />

        <!-- Dynamic Form Element Slots -->
        <base-row>
          <base-col
            v-for="(field, i) in fields.filter(Boolean)"
            :key="i + '-' + field.key"
            :col="
              field.row_col ? field.row_col : labelHorizontal ? 'full' : '1/2'
            "
            :class="[
              { 'd-none': field.component === 'hidden' },
              field._classes
            ]"
            class="mb-4"
          >
            <base-row class="base-form align-items-center">
              <base-col
                v-if="!noLabel"
                :col="
                  field._label_col
                    ? field._label_col
                    : labelHorizontal
                    ? '1/3'
                    : 'full'
                "
                :class="[
                  { 'mb-2': !labelHorizontal },
                  field._label_col_classes
                ]"
              >
                <label
                  :class="[
                    'my-auto',
                    field._label_classes,
                    { 'd-flex align-items-center': field.label_info }
                  ]"
                  class="font-semibold"
                  :for="field.key"
                >
                  {{ field.label }}
                  <span
                    class="ml-1 text-danger"
                    v-if="field.validation || field.hasAsterisk"
                    >*</span
                  >
                  <feather-icon
                    v-if="field.label_info"
                    v-tooltip="field.label_info"
                    icon="InfoIcon"
                    svg-classes="h-4 w-4 text-primary ml-2"
                  />
                </label>
              </base-col>

              <base-col
                :col="
                  field._input_col
                    ? field._input_col
                    : labelHorizontal
                    ? '2/3'
                    : 'full'
                "
                :key="i + '-' + field.key"
                :class="[field._input_classes]"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="field.validation"
                >
                  <slot
                    :name="field.key"
                    :isValueChanged="isValueChanged"
                    :setDefaultFormValue="setDefaultFormValue"
                    :disabled="field.disabled"
                  >
                    <template v-if="field.component === 'hidden'">
                      <input
                        :name="field.key"
                        :value="item[field.key]"
                        type="hidden"
                      />
                    </template>

                    <template v-else-if="field.component === 'input'">
                      <!-- v-tooltip.right="checkRestrict(field.restrict, item[field.key]) ? 'jen čísla': false" -->
                      <base-input
                        :value.sync="item[field.key]"
                        :id="field.key"
                        :label="field.label"
                        :type="field.type"
                        :name="field.key"
                        :disabled="field.disabled"
                        :readonly="field.readonly"
                        :icon="field.icon"
                        :focus="field.focus"
                        :placeholder="field.placeholder"
                        :is-error="errors[0] ? true : false"
                        :is-changed="isValueChanged(field.key)"
                        :autocomplete="
                          field.type === 'password' ? 'new-password' : 'on'
                        "
                        :only-numbers="field.onlyNumbers"
                        :maxlength="field.maxlength"
                        add-classes="w-full"
                        @before-update="setDefaultFormValue(field.key)"
                        @update:value="
                          (val) => $emit('update:' + field.key, val)
                        "
                      />
                    </template>

                    <template v-else-if="field.component === 'select'">
                      <!-- Problem with validation?? - solved -->
                      <base-select
                        :options="field.componentData"
                        :value.sync="item[field.key]"
                        :value-key="field.valueKey"
                        :label-key="field.labelKey"
                        :name="field.key"
                        :disabled="field.disabled"
                        :no-default-value="field.noDefaultValue"
                        :placeholder="field.componentPlaceholder"
                        :clearable="field.componentClearable"
                        :size="field.size"
                        :is-changed="isValueChanged(field.key)"
                        @before-update="setDefaultFormValue(field.key)"
                        @update:value="
                          (val) => $emit('update:' + field.key, val)
                        "
                      />
                    </template>

                    <template v-else-if="field.component === 'checkbox'">
                      <base-checkbox
                        :value.sync="item[field.key]"
                        :name="field.key"
                        :is-changed="isValueChanged(field.key)"
                        :checked-value="field.checkedValue"
                        :unchecked-value="field.uncheckedValue"
                        @before-update="setDefaultFormValue(field.key)"
                        @update:value="
                          (val) => $emit('update:' + field.key, val)
                        "
                      />
                    </template>

                    <template v-else-if="field.component === 'textarea'">
                      <base-textarea
                        :value.sync="item[field.key]"
                        :name="field.key"
                        :disabled="field.disabled"
                        :is-changed="isValueChanged(field.key)"
                        @before-update="setDefaultFormValue(field.key)"
                        @update:value="
                          (val) => $emit('update:' + field.key, val)
                        "
                      />
                    </template>

                    <template v-else-if="field.component === 'datepicker'">
                      <base-datepicker
                        :value.sync="item[field.key]"
                        :name="field.key"
                        :type="field.type"
                        :placeholder="field.componentPlaceholder"
                        :clearable="field.componentClearable"
                        :disabled="field.disabled"
                        :is-changed="isValueChanged(field.key)"
                        @before-update="setDefaultFormValue(field.key)"
                        @update:value="
                          (val) => $emit('update:' + field.key, val)
                        "
                        @focus="(val) => $emit('focus:' + field.key, val)"
                        @submit="(val) => $emit('change:' + field.key, val)"
                      />
                    </template>

                    <template v-else-if="field.component === 'radio'">
                      <base-radio
                        :options="field.option_labels"
                        :value.sync="item[field.key]"
                        :name="field.key"
                        :is-changed="isValueChanged(field.key)"
                        @before-update="setDefaultFormValue(field.key)"
                        @update:value="
                          (val) => $emit('update:' + field.key, val)
                        "
                      />
                    </template>

                    <div v-else>
                      {{ item[field.key] }}
                    </div>
                  </slot>

                  <!-- <div class="text-danger text-sm mt-2" v-if="errors[0]">
                  {{ handleError(errors[0]) }}
                </div> -->
                  <!-- show only first error msg for now -->
                </validation-provider>
                <!-- <span class="text-danger text-sm" v-if="checkRestrict(field.validation, item[field.key])">{{validationText[field.validation]}}</span> -->
              </base-col>
            </base-row>
          </base-col>

          <base-col col="full" v-if="hasRequiredInput">
            <small class="text-danger font-semibold">{{
              $tr('pole_oznacena_jsou_povinna')
            }}</small>
          </base-col>

          <base-col col="full" v-if="$slots['under-inputs']">
            <slot name="under-inputs" />
          </base-col>

          <base-col
            v-if="!noSaveButton"
            :col="buttonCol ? buttonCol : 'full'"
            class="my-auto text-center"
          >
            <slot name="button">
              <button
                type="submit"
                color="primary"
                class="vs-component vs-button mx-auto vs-button-primary vs-button-filled p-3 lg:mt-2"
                :disabled="disabled"
                @keyup.enter.stop
              >
                {{ buttonLabelLocal }}
              </button>
            </slot>
          </base-col>
        </base-row>

        <!-- Slot For Button -->
        <!-- <div v-if="!noSaveButton" class="w-full m-auto text-center">
          <slot name="button">
            <button
              type="submit"
              color="primary"
              class="vs-component vs-button mx-auto vs-button-primary vs-button-filled p-3"
              @keyup.enter.stop
            >
              {{ buttonLabel }}
            </button>
          </slot>
        </div> -->

        <!-- <div class="vx-row">
        <div class="vx-col lg:w-2/3 w-full ml-auto">
          <slot name="el-button" />
        </div>
    </div> --><!-- {{item}} -->
      </form>
    </div>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseForm',

  // Number of Elements In Form (exclude button)
  props: {
    fields: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    dispatchAction: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noSaveButton: {
      type: Boolean,
      default: false
    },
    labelHorizontal: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    buttonCol: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      defaultFormValues: {}
      /* validationText: {
        number: 'Toto pole musí obsahovat pouze číslo.',
        email: 'Neplatný formát e-mailu.',
        //text: 'Toto pole je moc dlouhé.',
      } */
    };
  },

  computed: {
    ...mapGetters(['NO_DATA_TEXT']),

    buttonLabelLocal() {
      if (this.buttonLabel) {
        return this.buttonLabel;
      }

      return this.$tr('ulozit');
    },

    hasRequiredInput() {
      let hasRequiredInput = false;

      this.fields.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(field, 'validation')) {
          hasRequiredInput = true;
        }
      });

      return hasRequiredInput;
    },

    valueChangedEnabledLocal() {
      return this.item.id > 0 || this.valueChangedEnabled;
    },

    isValueChanged() {
      return (field_key) => {
        return (
          this.valueChangedEnabledLocal &&
          this.$fnc.objectContainsKey(this.defaultFormValues, field_key) &&
          this.$fnc.objectContainsKey(this.item, field_key) &&
          !this.$fnc.compareValues(
            this.defaultFormValues[field_key],
            this.item[field_key]
          )
        );
      };
    }
  },

  methods: {
    handleError(error) {
      // Hide for now
      /* if (error) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } */

      return error;
    },

    setDefaultFormValue(field_key) {
      if (
        this.valueChangedEnabledLocal &&
        !this.$fnc.objectContainsKey(this.defaultFormValues, field_key)
      ) {
        this.$set(
          this.defaultFormValues,
          field_key,
          this.item[field_key] ?? ''
        );
      }
    },

    async saveData(e) {
      let postData = {};
      e.target.forEach((i) => {
        // console.log(i.name);
        if (
          i.name &&
          this.$fnc.objectContainsKey(this.item, i.name) &&
          !this.$fnc.compareValues(i.localName, 'button')
        ) {
          this.$set(postData, i.name, this.item[i.name]);
          i.blur();
        }
      });

      if (this.dispatchAction) {
        const data = await this.$store.dispatch(this.dispatchAction, postData);

        if (
          data !== undefined &&
          (data === true ||
            data.saved ||
            (data['detail'] && data['detail'].id) > 0 ||
            data.id > 0)
        ) {
          this.$emit('on-saved', data);
        } else {
          return this.$emit('on-error', data);
        }
      } else {
        this.$emit('save', postData);
      }

      this.defaultFormValues = {};
      /* this.$validator.validateAll().then(result => {
        if(result) this.$emit('save')
      }) */
    }

    /* checkRestrict(restriction, value) {
      if(!value) return false
      if(restriction === 'number') {
        if(!this.$fnc.isNumber(value)) return true
      }
      else if(restriction === 'email') {
        if(!this.$fnc.isEmail(value)) return true
      }
      else return false
    } */
  }
};
</script>

<style>
.vs-select--options ul:nth-of-type(2) {
  display: none;
}
</style>
