/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList';
import themeConfig from '@/../themeConfig.js';

// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userData = {
  // user info // From Auth
  id: 0,
  last_update: false,
  name: 'John',
  surname: 'Wicc',
  photoURL: require('@/assets/images/portrait/small/no-image.jpg'),
  workgroup_id: 0,
  workgroup_name: 'Bot',
  status: true,
  unseenMessages: 0,
  // user privileges and other settings
  view_all: 'N',
  caninsertusersheet: 'N',
  canapproveworkflow: 'N',
  canapproveholiday: 'N',
  canassignworkflow: 'N',
  canassignworkflowall: 'N',
  cancontrolcorrection: 'N',
  caneditworkflow: 'N',
  hiddenRangeTypes2: {}
  //about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  //userRole    : "admin"
};

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const getDefaultState = () => {
  let localSettings = {};
  if (localStorage.getItem('local_settings')) {
    try {
      localSettings = JSON.parse(localStorage.getItem('local_settings'));
    } catch (e) {
      localSettings = {};
    }
  }

  return {
    appName: process.env.VUE_APP_TITLE,
    devCompanyURL: process.env.VUE_APP_DEV_COMPANY_URL,
    devCompanyName: process.env.VUE_APP_DEV_COMPANY_NAME,
    apiURL: process.env.VUE_APP_API_URL,
    apiInProgress: false,

    appSettings: {},

    token_key: 'b_auth_token',
    access_token: undefined,
    isUserLogged: false,
    AppActiveUser: userData,
    tescoType: 'tesco',

    default_date: '1000-01-01',
    default_datetime: '1000-01-01 00:00:00',
    workflowDefaultTime: '23:59:00', // '23:59:59' // sek ne, nelze prece nastavovat sek..

    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    is_touch_device: is_touch_device(),
    navbarSearchAndPinList: navbarSearchAndPinList,
    verticalNavMenuWidth: 'default',
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: navbarSearchAndPinList['pages'].data.filter(
      (page) => page.is_bookmarked
    ),
    themeColors: themeConfig.colors,
    // Local app settings
    themePrimaryColor:
      localSettings.themePrimaryColor || themeConfig.colors.primary,
    theme: localSettings.theme || themeConfig.theme || 'light',
    mainLayoutType:
      localSettings.mainLayoutType || themeConfig.mainLayoutType || 'vertical',
    navbarType: localSettings.navbarType || themeConfig.navbarType,
    navbarColor: localSettings.navbarColor || themeConfig.navbarColor,
    reduceButton: localSettings.reduceButton || themeConfig.sidebarCollapsed,
    footerType: localSettings.footerType || themeConfig.footerType || 'static',
    hideScrollToTop:
      localSettings.hideScrollToTop || themeConfig.hideScrollToTop,
    routerTransition:
      localSettings.routerTransition || themeConfig.routerTransition,
    navbarCollapsed: false,
    hideUserPhotos: localSettings.hideUserPhotos || false,
    // Globals for ui components
    // noDataText: 'Žádné položky',
    maxItems: 20,
    // File extensions allowed
    extensionsAllowed:
      '.pdf, .doc, .docx, .odt, .xls, .xlsx, .jpg, .png, .txt, .zip, .rar, .7z, .tar, .gz, .bz2, .xz',

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null
  };
};

const state = getDefaultState();

export default state;
