<template>
  <select v-model="$i18n.locale" @change="setLanguage()">
    <option
      v-for="lang in SYSTEM_LANGUAGES"
      :key="`locale-${lang.code}`"
      :value="lang.code"
    >
      {{ lang.code }}
    </option>
  </select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguagesPanel',

  props: {
    size: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['SYSTEM_LANGUAGES'])
  },

  methods: {
    async setLanguage(lang) {
      // this.$i18n.locale = lang;
      localStorage.setItem('app_language', this.$i18n.locale);

      await this.$store.dispatch('translations');
      this.$fnc.changeDocumentTitle(this.$route);
    }
  }
};
</script>

<style scoped>
.lang-flag-inact {
  filter: grayscale(100%);
}
</style>
